import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/logins";
import companyLogo from "../assets/img/logosync.png"; 
import { Link, useHistory } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Footer from "../components/Footer";
import "../assets/css/login.css";

export default function Login() {
  const dispatch = useDispatch();
  const [loginAttempt, setLoginAttempt] = useState(true);
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    const login_credentials = {
      username: e.target[0].value,
      password: e.target[1].value,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login/`, login_credentials)
      .then((res) => {
        if (res.data.status === true) {
          setLoginAttempt(true);
          dispatch(
            loginUser({ userId: res.data.doctor_id, token: res.data.token })
          );
          localStorage.setItem("status", true);
          localStorage.setItem("userId", res.data.doctor.id);
          localStorage.setItem("token", res.data.token);
          history.push({
            pathname: "/dashboard",
          });
        } else {
          setLoginAttempt(false);
        }
      });
  }

  return (
    <>
      <section className="login-main">
        <div className="container h-100">
          <div className="row justify-content-center p-0 m-0 h-100">
            <div className="col-xxl-5 col-lg-5 col-md-12 col-sm-12 py-5 h-100">
              <div className="content">
                <div className="div-image">
                  <img
                    src={companyLogo}
                    className="logo-image-class"
                    alt="company logo"
                  />
                </div>
                <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                  <div className="card-title">
                    <h2 className="p-3 pb-1">Sign in</h2>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit} className="was-validated">
                      <div className="mb-4">
                        <label
                          htmlFor="username"
                          className="form-label login-form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control login-form-input"
                          id="username"
                          required
                        />
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">
                          Please fill out this field.
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="d-flex justify-content-between">
                          <label
                            htmlFor="password"
                            className="form-label login-form-label"
                          >
                            Password
                          </label>
                          <p className="forgot-text">
                            <a href="/#">Forgot your Password?</a>
                          </p>
                        </div>
                        <input
                          type="password"
                          className="form-control login-form-input"
                          id="password"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <div className="new">
                          <div className="form-group-new">
                            <input type="checkbox" id="html" />
                            <label htmlFor="html">Stay signed in</label>
                          </div>
                        </div>
                      </div>
                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn text-light main-bg btn-style"
                        >
                          <b>Login</b>
                        </button>
                        <p className="text-center my-4 bottom-text">
                        Don't have an account? <Link to="/register">Sign Up</Link>
                        </p>
                      </div>
                      {!loginAttempt && (
                        <div style={{ paddingTop: "15px" }}>
                          <Alert variant="danger">
                            <p>Invalid Username or Password</p>
                          </Alert>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
