import Slider from "@mui/material/Slider";
import axios from "axios";
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useEffect, useRef, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminPanelHeader from "./AdminPanelHeader";
import SideBar from "./SideBar";

export default function Recording(props) {
  // For range slidar
  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);

  const [value, setValue] = React.useState([0, 1000]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStartRange(newValue[0]);
    setEndRange(newValue[1]);
    // console.log(newValue);
  };

  //for filters above main graph
  const [lffValue, setLffValue] = useState("1Hz");
  const [hffValue, setHffValue] = useState("70Hz");
  const [notchValue, setNotchValue] = useState("60Hz");
  const [alert, setAlert] = useState();

  //for main graph
  const [myChart, setMyChart] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(null);
  const [eegData, setEegData] = useState([]);
  const [filteredEegData, setFilteredEegData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const myRef = useRef();

  // for Pwelch Plot
  const [showPwelchPlot, setShowPwelchPlot] = useState(0);
  const [pwelchChart, setPwelchChart] = useState(null);
  const [pwelchEegData, setPwelchEegData] = useState([]);
  const [pwelchFilteredData, setPwelchFilteredData] = useState(null);
  const [pwelchIsFilter, setPwelchIsFilter] = useState(0);
  const [pwelchLoading, setPwelchLoading] = useState(true);
  const [pwelchAlert, setPwelchAlert] = useState();
  const myRef2 = useRef();
  const isMounted = useRef(false);

  // for Spectogram
  const [heatMapUrl, setHeatMapUrl] = useState();
  const [isHeatMap, setIsHeatMap] = useState(false);

  const token = useSelector((state) => state.loginData.token);
  Chart.register(zoomPlugin);

  const [requestStatus, setRequestStatus] = useState(false);

  //For Modal  to save graph values
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isDataSaved, setIsDataSaved] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDataSaved(true);
    if (startRange < endRange) {
      setRequestStatus(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/save_recordings`,
          {
            patient_visit_id: props.match.params.id,
            start_time: startRange,
            end_time: endRange,
            clinical_condition: e.target[0].value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((response) => {
          setRequestStatus(false);
          if (response.data.code === 401) {
            localStorage.clear();
            window.location.reload();
          } else {
            if (response.data.status === false) {
              setIsDataSaved(false);
              toast.error(response.data.data);
            } else {
              setIsDataSaved(false);
              toast.success("Data saved successfully");
              setShow(false);
            }
          }
        });
    } else {
      toast.error("Start range should be less than End range");
    }
  }
  // Second UseEffect to be executed, it populate the EEG data on the graph
  useEffect(() => {
    if (isMounted.current) {
      if (eegData.length === 0) {
        return;
      }
      if (myChart != null) {
        myChart.destroy();
      }
      setMyChart(
        new Chart(myRef.current, {
          type: "line",
          data: {
            labels: eegData.length === 0 ? [] : eegData.length[dataCount],
            datasets: [
              {
                label: "CH1 Voltage",
                data:
                  eegData.length === 0
                    ? []
                    : isFilter
                    ? filteredEegData.CH1_Voltage[dataCount]
                    : eegData.CH1_Voltage[dataCount],
                backgroundColor: ["rgba(55, 212, 123,0.6)"],
                pointRadius: 0,
                borderWidth: 1.3,
                borderColor: ["rgba(55, 212, 123,0.8)"],
              },
              {
                label: "CH2 Voltage",
                data:
                  eegData.length === 0
                    ? []
                    : isFilter
                    ? filteredEegData.CH2_Voltage[dataCount]
                    : eegData.CH2_Voltage[dataCount],
                backgroundColor: ["rgba(64, 163, 230,0.6)"],
                pointRadius: 0,
                borderWidth: 1.3,
                borderColor: ["rgba(64, 163, 230,0.8)"],
              },
            ],
          },
          width: 400,
          height: 1000,
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Time (seconds)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Voltages",
                },
              },
            },
            normalized: true,
            maintainAspectRatio: false,
            animation: false,
            spanGaps: true,
            datasets: {
              line: {
                pointRadius: 0, // disable for all `'line'` datasets
              },
            },
            elements: {
              point: {
                radius: 0, // default to disabled in all datasets
              },
            },
            plugins: {
              decimation: {
                enabled: true,
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x",
                  rangeMin: { x: 0 },
                  rangeMax: { x: 100 },
                },
                zoom: {
                  wheel: {
                    enabled: true,
                    mode: "xy",
                    rangeMin: { x: 0 },
                    rangeMax: { x: 100 },
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: "x",
                },
              },
            },
          },
        })
      );
    }
  }, [eegData, dataCount, isFilter]);

  // Third UseEffect to be executed it will fill the array for Pwelch Plot.
  useEffect(() => {
    if (isMounted.current) {
      const getData = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/pwelch_plot/${props.match.params.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          if (response.data.message !== "error") {
            setPwelchEegData(response.data.data);
            setPwelchLoading(false);
            setHeatMapUrl(response.data.data.plot_url);
            setIsHeatMap(true);
          } else {
            setPwelchAlert(response.data.message);
          }
        }
      };

      getData();
    }
  }, [showPwelchPlot]);

  // Fourth UseEffect to be executed, it will populate the pwelch data in Pwelch plot graph area
  useEffect(() => {
    if (isMounted.current) {
      if (pwelchEegData.length === 0) {
        return;
      }
      if (pwelchChart != null) {
        pwelchChart.destroy();
      }

      setPwelchChart(
        new Chart(myRef2.current, {
          type: "line",
          data: {
            labels: pwelchEegData.length === 0 ? [] : pwelchEegData.length,
            datasets: [
              {
                label: "CH1 Voltage",
                data:
                  pwelchEegData.length === 0
                    ? []
                    : pwelchIsFilter
                    ? pwelchFilteredData.CH1_Voltage
                    : pwelchEegData.CH1_Voltage,
                backgroundColor: ["rgba(55, 212, 123,0.6)"],
                pointRadius: 0,
                borderWidth: 1.3,
                borderColor: ["rgba(55, 212, 123,0.8)"],
              },
              {
                label: "CH2 Voltage",
                data:
                  pwelchEegData.length === 0
                    ? []
                    : pwelchIsFilter
                    ? pwelchFilteredData.CH2_Voltage
                    : pwelchEegData.CH2_Voltage,
                backgroundColor: ["rgba(64, 163, 230,0.6)"],
                pointRadius: 0,
                borderWidth: 1.3,
                borderColor: ["rgba(64, 163, 230,0.8)"],
              },
            ],
          },
          width: 300,
          height: 500,
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Time (seconds)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Voltages",
                },
              },
            },
            normalized: true,
            maintainAspectRatio: false,
            animation: false,
            spanGaps: true,
            datasets: {
              line: {
                pointRadius: 0, // disable for all `'line'` datasets
              },
            },
            elements: {
              point: {
                radius: 0, // default to disabled in all datasets
              },
            },
            plugins: {
              decimation: {
                enabled: true,
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x",
                  rangeMin: { x: 0 },
                  rangeMax: { x: 100 },
                },
                zoom: {
                  wheel: {
                    enabled: true,
                    mode: "xy",
                    rangeMin: { x: 0 },
                    rangeMax: { x: 100 },
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: "x",
                },
              },
            },
          },
        })
      );
    }
  }, [pwelchEegData, pwelchIsFilter]);

  // First UseEffect to be executed and will fill the array of EEG data
  useEffect(() => {
    try {
    } catch (error) {
      console.log(error);
    }
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/eeg_file/${props.match.params.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          isMounted.current = true;
          if (response.data.message !== "error") {
            setEegData(response.data.data);
            setDataLength(response.data.data.length.length);
            setLoading(false);
            setShowPwelchPlot(1);
          } else {
            setAlert(response.data.message);
          }
        }
      } catch (error) {
        toast.error("No relevant file EEG file found for the given patient", {autoClose: 3000, position: "top-right"});
        setLoading(false);
        // setAlert("No relevant file EEG file found for the given patient");
      }
    };

    getData();
  }, []);

  const applyFilters = () => {
    setIsHeatMap(false);
    setIsDataSaved(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/filters`,
        {
          patient_visit_id: props.match.params.id,
          LFF: lffValue,
          HFF: hffValue,
          Notch: notchValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setIsDataSaved(false);
        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          if (response.data.status === false) {
          } else {
            setLoading(true);
            setIsFilter(false);
            setFilteredEegData([]);
            setFilteredEegData(response.data.data);
            setIsFilter(true);
            setLoading(false);

            setPwelchFilteredData(response.data.data);
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/pwelch_filters`,
                {
                  patient_visit_id: props.match.params.id,
                  LFF: lffValue,
                  HFF: hffValue,
                  Notch: notchValue,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },
                }
              )
              .then((response) => {
                if (response.data.code === 401) {
                  localStorage.clear();
                  window.location.reload();
                } else {
                  if (response.data.status === false) {
                  } else {
                    setPwelchIsFilter(0);
                    setPwelchFilteredData([]);
                    setPwelchFilteredData(response.data.data);
                    setHeatMapUrl(response.data.data.plot_url);
                    setIsHeatMap(true);
                    setPwelchIsFilter(1);
                  }
                }
              });
          }
        }
      });
  };

  return (
    <div>
      <AdminPanelHeader />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {alert ? (
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Data Not Available</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>No data was found for this patient visit </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                props.history.goBack();
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      ) : (
        <section className="dashboard-sections review-recording">
          <div className="container-fluid">
            <div className="dashboard-inner row">
              <div className="left-sidebar col-lg-2">
                <div className="left-section-header">
                  <div className="ls-patient-name">Kimberly Cousins</div>
                  <div className="ls-patient-status">
                    75F <span /> Sepsis
                  </div>
                  <div className="ls-dor">DOR: 04/21/21</div>
                </div>
                <nav className="navbar navbar-expand-lg">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <SideBar />
                  </div>
                </nav>
              </div>

              <div className="patient-overview col-lg-6">
                <div className="rocording-stats-box">
                  <div className="display-scale">
                    <div className="recording-terms">
                      Display: <span className="blue-color">10s</span>
                    </div>
                    <div className="recording-terms">
                      Scale: <span className="blue-clter(trolor">10s</span>
                    </div>
                    <div className="recordingLine-terms">
                      QEEG: <span className="blue-color">Spectrogram</span>
                    </div>
                  </div>
                  <div className="lef-hff">
                    <div className="recording-terms">
                      <div className="input-group">
                        <div>LFF: </div>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn
                            green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="1Hz"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {lffValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("1Hz")}
                            >
                              1Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("5Hz")}
                            >
                              5Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("15Hz")}
                            >
                              15Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("20Hz")}
                            >
                              20Hz
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="recording-terms">
                      <div className="input-group">
                        <div>HFF: </div>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {hffValue}
                          </button>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("15Hz")}
                            >
                              15 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("30Hz")}
                            >
                              30 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("40Hz")}
                            >
                              40 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("70Hz")}
                            >
                              70 Hz
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="recording-terms">
                      <div className="input-group">
                        <div>Notch: </div>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn btn-sm green-btn2 dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {notchValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("N/A");
                              }}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("50Hz");
                              }}
                            >
                              50 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("60Hz");
                              }}
                            >
                              60 Hz
                            </button>
                          </div>
                        </div>
                        <div style={{ paddingLeft: "15px" }}>
                          <button
                            className="btn green-btn3 btn-sm"
                            type="button"
                            style={{
                              fontWeight: "bold",
                              fontSize: "6",
                            }}
                            disabled={requestStatus}
                            onClick={() => {
                              applyFilters(lffValue, hffValue, notchValue);
                            }}
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="change-nochange">
                    <div className="change-switch">
                      Change
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" />
                      </label>
                      No Change
                    </div>
                    <div className="change-switch">
                      Critical
                      <label className="switch">
                        <input type="checkbox" defaultChecked />
                        <span className="slider round" />
                      </label>
                      Not Critical
                    </div>
                  </div>
                </div>

                {/* Graph Section */}
                {loading ? (
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "2%",
                    }}
                  >
                    {" "}
                    <ClipLoader />{" "}
                    <h3 style={{ fontFamily: "lato" }}>Graph Loading...</h3>
                  </div>
                ) : (
                  <div className="empty-box">
                    <div>
                      <button
                        disabled={dataCount === 0 ? true : false}
                        className={
                          dataCount === 0 ? "btn grey-btn" : "btn green-btn"
                        }
                        onClick={() => {
                          setDataCount(dataCount - 1);
                          setValue([0, 1000]);
                        }}
                      >
                        Previous Window
                      </button>
                    </div>

                    <div>
                      <button
                        disabled={dataCount === dataLength - 1 ? true : false}
                        className={
                          dataCount === dataLength - 1
                            ? "btn grey-btn"
                            : "btn green-btn"
                        }
                        onClick={() => {
                          setDataCount(dataCount + 1);
                          setValue([0, 1000]);
                        }}
                      >
                        Next Window
                      </button>
                    </div>
                  </div>
                )}
                <div className="graph-box">
                  <canvas id="chart" ref={myRef} height="400" />
                </div>
                <div>
                  <br />
                  <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={value}
                    min={
                      dataCount > 0 ? parseInt(eegData.length[dataCount][0]) : 0
                    }
                    step={0.1}
                    max={
                      dataCount > 0
                        ? parseInt(eegData.length[dataCount].slice(-1)[0])
                        : 10
                    }
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    // getAriaValueText={valuetext}
                  />
                </div>
                <div className="empty-box zoomButtons">
                  {loading ? (
                    <div
                      style={{
                        textAlign: "center",
                        paddingLeft: "48%",
                      }}
                    >
                      {" "}
                      {/* <ClipLoader />{" "} */}
                    </div>
                  ) : (
                    <>
                      <div>
                        <button
                          className={"btn red-btn"}
                          onClick={() => {
                            myChart.zoom(1.1);
                          }}
                        >
                          Zoom In
                        </button>
                      </div>

                      <div>
                        <button
                          className={"btn red-btn"}
                          onClick={() => {
                            myChart.zoom(0.9);
                          }}
                        >
                          Zoom Out
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="right-boxes col-lg-4">
                <div className="recording-buttons">
                  <a className="green-btn" onClick={handleShow}>
                    Save Plot Values
                  </a>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Save Plot Values</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div
                        style={{
                          color: "red",
                          fontSize: "17px",
                          textAlign: "center",
                        }}
                      >
                        Start and end time from main graph
                      </div>
                      <br />

                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={value}
                        min={
                          dataCount > 0
                            ? parseInt(eegData.length[dataCount][0])
                            : 0
                        }
                        step={0.1}
                        max={
                          dataCount > 0
                            ? parseInt(eegData.length[dataCount].slice(-1)[0])
                            : 10
                        }
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        // getAriaValueText={valuetext}
                      />

                      <Form onSubmit={(e) => handleSubmit(e)}>
                        {/* <FloatingLabel
                          controlId="floatingInput"
                          label="Start Range"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="name@example.com"
                            step="any"
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="End Range"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Password"
                            step="any"
                          />
                        </FloatingLabel> */}

                        {/* <br /> */}
                        <FloatingLabel
                          controlId="floatingSelect"
                          label="Clinical Condition"
                        >
                          <Form.Select aria-label="Floating label select example">
                            {/* <option>Open this select menu</option> */}
                            <option value="1">Seizure</option>
                            <option value="2">Concussions</option>
                            <option value="3">Stroke</option>
                          </Form.Select>
                        </FloatingLabel>
                        <br />
                        <div
                          style={{
                            paddingTop: "8px",
                            textAlign: "right",
                          }}
                        >
                          {isDataSaved ? (
                            <div
                              style={{
                                textAlign: "center",
                                // paddingTop: "2%",
                              }}
                            >
                              {" "}
                              <ClipLoader />{" "}
                              <p style={{ fontFamily: "lato" }}>
                                Saving values
                              </p>
                            </div>
                          ) : (
                            <div></div>
                          )}{" "}
                          <Button
                            variant="danger"
                            onClick={handleClose}
                            style={{ marginRight: "10px" }}
                          >
                            Close
                          </Button>
                          <Button
                            variant="success"
                            type="submit"
                            disabled={requestStatus}
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </Modal.Body>
                  </Modal>
                  <a className="light-share-btn" href="/#">
                    Share Report
                  </a>
                </div>
                <div className="dashboard-inbox">
                  <div className="inbox-title">
                    <h3>
                      {" "}
                      Pwelch Plot {isHeatMap ? (
                        <div></div>
                      ) : (
                        <ClipLoader />
                      )}{" "}
                    </h3>
                  </div>
                  <div className="inbox-body">
                    <div className="graph-box">
                      <canvas id="chart2" ref={myRef2} height="400" />
                    </div>
                  </div>
                </div>
                <div className="dashboard-inbox calendar-box">
                  <div className="inbox-title">
                    <h3>
                      Spectrogram Plot{" "}
                      {isHeatMap ? <div></div> : <ClipLoader />}{" "}
                    </h3>
                  </div>
                  <div className="graph-box">
                    {isHeatMap ? (
                      <img
                        src={heatMapUrl}
                        alt="plot"
                        width="100%"
                        height="450px"
                      />
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          paddingTop: "2%",
                        }}
                      >
                        {" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
