import { faArrowRight, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row
} from "react-bootstrap";
import Recaptcha from "react-recaptcha";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style2.css";
import companyLogo from "../assets/img/Company_logo_500x250.png";
import Footer from "../components/Footer";
import { loginUser } from "../redux/logins";

// import Alert from "react-popup-alert";

export default function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordMatched, setPasswordMatch] = useState(true);
  const [userExists, setUserExists] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModel, setShowPaymentModel] = useState(false);

  const handleClose = () => setShowModal(false);
  const handlePaymentClose = () => setShowPaymentModel(false);
  // const handleShow = () => setShowModal(true);

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [gender, setGender] = useState(0);
  const [hospitalId, setHospitalId] = useState();
  const [isHospitalSelected, setIsHospitalSelected] = useState(false);
  const [options, setOptions] = useState([]);

  const registrationDetails = [];
  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/hospital`
      );
      const hospitalsTemp = [];
      if (response.data.code === 200) {
        const hospitals = response.data.data;
        for (let i = 0; i < hospitals.length; i++) {
          hospitalsTemp.push({
            value: hospitals[i].id,
            label: hospitals[i].name,
          });
        }
        setOptions(hospitalsTemp);
      }
    };
    getData();
  }, []);

  function handleChange(selectedOption) {
    setSelectedOption(selectedOption);
    setHospitalId(selectedOption.value);
    setIsHospitalSelected(true);
  }

  function openModalForm(e) {
    e.preventDefault();
    const signUp_credentials = {
      //  username: e.target[0].value,
      email: e.target[0].value,
      password: e.target[1].value,
      confirm_password: e.target[2].value,
    };

    if (
      signUp_credentials["password"] !== signUp_credentials["confirm_password"]
    ) {
      setPasswordMatch(false);
    } else {
      if (captchaVerified === false) {
        toast.error("Verify Captcha to proceed");
      } else {
        registrationDetails.push(signUp_credentials);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/register_check`,
            signUp_credentials
          )
          .then((res) => {
            if (res.data.status === true) {
              setUserExists(false);
              setEmailExists(false);
              setShowModal(true);

            } else {
              setUsernameErrorMessage("");
              setEmailErrorMessage("");
              if (res.data.message === "email_error") {
                setEmailExists(true);
                setEmailErrorMessage(res.data.data);
              } else if (res.data.message === "username_error") {
                setUserExists(true);
                setUsernameErrorMessage(res.data.data);
              }
            }
          });
        setPasswordMatch(true);
      }
    }

    // axios
    // 	.post(`${process.env.REACT_APP_BASE_URL}/login/`, signUp_credentials)
    // 	.then((res) => {
    // 		if (res.data.status === true) {
    // 			history.push("/dashboard");
    // 		}
    // 	});
  }

  function handleSubmit2(event) {
    const form = event.currentTarget;

    if (isHospitalSelected === false) {
      toast.error("Select a Hospital");
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      } else {
        const signUp_credentials2 = {
          username,
          password,
          confirmPassword,
          firstName: event.target[0].value,
          lastName: event.target[1].value,
          dob: event.target[2].value,
          email: email,
          // displayImage: event.target[3].value,
          age: event.target[3].value,
          gender: gender,
          phoneNo: event.target[6].value,
          emergencyNo: event.target[7].value,
          hospitalId: hospitalId,
        };

        registrationDetails.push(signUp_credentials2);
        event.preventDefault();
        setValidated(true);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/register`,
            signUp_credentials2
          )
          .then((res) => {
            if (res.data.status === true) {
              dispatch(
                loginUser({
                  userId: res.data.doctor_id,
                  token: res.data.token,
                })
              );
              localStorage.setItem("status", true);
              localStorage.setItem("userId", res.data.doctor_id);
              localStorage.setItem("token", res.data.token);

              history.push("/dashboard");
              // FIXME: commented out for testing
              setShowModal(false);
              // setShowPaymentModel(true);
            }
          });
      }
    }
  }

  function handleSubmitPayment(event) {
    const signUp_credentials2 = {
      username,
      password,
      confirmPassword,
      firstName: event.target[0].value,
      lastName: event.target[1].value,
      dob: event.target[2].value,
      email: email,
      age: event.target[3].value,
      gender: gender,
      phoneNo: event.target[6].value,
      emergencyNo: event.target[7].value,
      hospitalId: hospitalId,
    };

    registrationDetails.push(signUp_credentials2);
    event.preventDefault();
    setValidated(true);
  }

  const location = useLocation();
  const stripe = loadStripe(
    "pk_test_51L8OQPDSGtKetiSULvv0qIvrud9sorFxPlZcyTUw6cgfQwWTUtwovXaMCktYfRlzEUb8EMIbJGEYqR32Gt8HkFWe00zaND3eu0"
  );
  const API_URL = "http://localhost:8000";
  const api = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-type": "application/json",
    },
  });

  var myHeaders = new Headers();
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch("http://localhost:8000/config/", requestOptions)
      .then((response) => response.text())
      .then((res) => {
        if (res.data.status === true) {
          dispatch(
            loginUser({
              userId: res.data.doctor_id,
              token: res.data.token,
            })
          );
          localStorage.setItem("status", true);
          localStorage.setItem("userId", res.data.doctor_id);
          localStorage.setItem("token", res.data.token);
          console.log(res.data.token);
          history.push("/dashboard");
        }
      })
      .catch((error) => console.log("error", error));
  }, []);
  useEffect(() => {
    const values = QueryString.parse(location.search);
    if (values.success) {
      console.log("Order placed -- We will update you soon.");
    }

    if (values.canceled) {
      console.log(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="login-bg">
        <div className="boxes-in-bg" />
      </div>
      {/*Registration form Modal */}
      {showModal ? (
        <div>
          {" "}
          <Modal
            show={showModal}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Sign Up Form</Modal.Title>
            </Modal.Header>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit2(e, registrationDetails)}
            >
              <Modal.Body className="show-grid">
                <Container>
                  <Row>
                    <Form.Group
                      as={Col}
                      md="6"
                      xs="12"
                      controlId="validationCustom01"
                    >
                      <Form.Label>
                        First Name{" "}
                        <span style={{ color: "red", marginLeft: "0.1rem" }}>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" required />
                      <Form.Control.Feedback type="invalid">
                        Enter First Name.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      xs="12"
                      controlId="validationCustom01"
                    >
                      <Form.Label>
                        Last Name{" "}
                        <span style={{ color: "red", marginLeft: "0.1rem" }}>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" required />
                      <Form.Control.Feedback type="invalid">
                        Enter Last Name.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      xs="12"
                      controlId="validationCustom03"
                    >
                      <Form.Label>
                        Date of Birth{" "}
                        <span style={{ color: "red", marginLeft: "0.1rem" }}>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="date" size="sm" required />
                      <Form.Control.Feedback type="invalid">
                        Enter a valid birth date.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group
                      as={Col}
                      md="7"
                      xs="12"
                      controlId="validationCustom04"
                    >
                      <Form.Label>Display Image</Form.Label>
                      <Form.Control type="file" size="sm" />
                    </Form.Group> */}
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="5"
                      xs="6"
                      controlId="validationCustom05"
                    >
                      <Form.Label>
                        Age{" "}
                        <span style={{ color: "red", marginLeft: "0.1rem" }}>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="number" size="sm" min="1" required />
                      <Form.Control.Feedback type="invalid">
                        Enter valid age.
                      </Form.Control.Feedback>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="7"
                      xs="6"
                      controlId="validationCustom06"
                      style={{ paddingTop: "28px" }}
                      required
                    >
                      <Form.Check
                        inline
                        name="group1"
                        type="radio"
                        label="Male"
                        id="1"
                        onClick={(e) => {
                          setGender(1);
                        }}
                        required
                      />
                      <Form.Check
                        inline
                        name="group1"
                        type="radio"
                        label="Female"
                        id="2"
                        onClick={(e) => {
                          setGender(2);
                        }}
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="5"
                      xs="12"
                      controlId="validationCustom07"
                    >
                      <Form.Label>
                        Phone No.{" "}
                        <span style={{ color: "red", marginLeft: "0.1rem" }}>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="number" size="sm" required />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="7"
                      xs="12"
                      controlId="validationCustom08"
                    >
                      <Form.Label>Emergency Contact</Form.Label>
                      <Form.Control type="number" size="sm" />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      xs="12"
                      controlId="validationCustom09"
                      style={{ paddingTop: "15px" }}
                      required={true}
                    >
                      <Form.Label>
                        Hospital
                        <span style={{ color: "red", marginLeft: "0.3rem" }}>
                          *
                        </span>
                      </Form.Label>
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                        defaultValue={options[0]}
                      />
                    </Form.Group>
                  </Row>

                  {/* <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      xs="12"
                      controlId="validationCustom10"
                      style={{ paddingTop: "15px" }}
                    >
                      <Recaptcha
                        sitekey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}`}
                        render="explicit"
                        onloadCallback={(e) => {}}
                        verifyCallback={(e) => {
                          setCaptchaVerified(true);
                        }}
                        expiredCallback={(e) => {
                          setCaptchaVerified(false);
                        }}
                      />
                    </Form.Group>
                  </Row> */}
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  style={{ backgroundColor: "rgba(0, 107, 111, 0.7)" }}
                  type="submit"
                >
                  Continue
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
      {showPaymentModel && (
        <div>
          {" "}
          <Modal
            show={showPaymentModel}
            onHide={handlePaymentClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="text-center"
          >
            <Modal.Header closeButton className="text-center">
              <Modal.Title className="  font-weight-bold text-primary paymentstandard">
                Standard
              </Modal.Title>
            </Modal.Header>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmitPayment(e, registrationDetails)}
            >
              <Modal.Body className="show-grid">
                <Container>
                  <p className="p-1 text-muted h4 ">
                    One easy to understand pricing plan for all of our users!
                    Get complete access to all of our features.
                  </p>
                  <h1
                    className="d-inline  paymentprice"
                    style={{
                      fontSize: "100px",
                      paddingTop: "20px",
                      paddingBottom: "40px",
                    }}
                  >
                    $9
                  </h1>
                  <h3 className="d-inline">/mo</h3>
                  <br />
                  <FontAwesomeIcon
                    className=" text-primary pr-2  pt-3  "
                    icon={faCircleCheck}
                  />
                  <p className="pt-3 d-inline text-muted h4">
                    Unlimited access to all of our products
                  </p>
                  <br />
                  <FontAwesomeIcon
                    className=" text-primary  pr-2 pt-3"
                    icon={faCircleCheck}
                  />{" "}
                  <p className=" d-inline pt-3 text-muted h4">
                    No setup fees or any hidden fees
                  </p>
                  <br />
                  <FontAwesomeIcon
                    className=" text-primary pr-2 pt-3 "
                    icon={faCircleCheck}
                  />{" "}
                  <p className="d-inline pt-3 pb-3 mb-5 text-muted h4">
                    Dedicated customer support
                  </p>
                </Container>
              </Modal.Body>
              <Modal.Footer className="mt-4">
                <form
                  action={`${API_URL}/api/stripe/create-checkout-session`}
                  method="POST"
                  className="bgcolour"
                >
                  <Button
                    className="btncolour text-primary btn btn-outline-light"
                    type="submit"
                    id="submitBtn"
                  >
                    Start now{" "}
                    <FontAwesomeIcon
                      className=" text-primary pr-2 pt-2 ps-3 "
                      icon={faArrowRight}
                    />{" "}
                  </Button>
                </form>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      )}
      {/* Login Section */}
      <section className="login-page">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-7">
              <div className="login-logo text-center">
                <Link to="/">
                  <img
                    className="logo-img"
                    src={companyLogo}
                    alt="company-logo"
                  />
                </Link>
              </div>
              <div className="login-form">
                <form action onSubmit={openModalForm}>
                  <div className="form-title">
                    <h3>Sign Up</h3>
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="email-address">Username</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Username"
                      id="user-name"
                      required="true"
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                    <p style={{ color: "grey", fontSize: "10px" }}>
                      Required. Less than 35 characters and greater than 2.
                      Letters, digits and ._ only. Start with letters only
                    </p>
                    {userExists ? (
                      <div>
                        {" "}
                        <p style={{ color: "red", fontSize: "15px" }}>
                          {usernameErrorMessage}
                        </p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="email-address">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      id="email-address"
                      required="true"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    {emailExists ? (
                      <div style={{ paddingTop: "px" }}>
                        {" "}
                        <p style={{ color: "red", fontSize: "15px" }}>
                          {emailErrorMessage}
                        </p>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className="form-group">
                    <div className="password-label">
                      <label htmlFor="password">Password</label>
                    </div>
                    <input
                      type="password"
                      name="password"
                      id="password1"
                      placeholder="New Password"
                      autoComplete="off"
                      required={true}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <div className="password-label">
                      <label htmlFor="password">Confirm Password</label>
                    </div>
                    <input
                      type="password"
                      name="password"
                      id="password2"
                      placeholder="Re-Enter Password"
                      autoComplete="off"
                      required={true}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </div>
                  {passwordMatched ? (
                    <div></div>
                  ) : (
                    <div style={{ paddingTop: "0px" }}>
                      {/* {toast.error(
                        "Password and confirmation password do not matchl"
                      )} */}
                      <Alert variant="danger">
                        <p>Password and confirmation password do not match</p>
                      </Alert>
                    </div>
                  )}

                  <Recaptcha
                    sitekey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}`}
                    render="explicit"
                    onloadCallback={(e) => {
                      console.log("true");
                    }}
                    verifyCallback={(e) => {
                      setCaptchaVerified(true);
                    }}
                    expiredCallback={(e) => {
                      setCaptchaVerified(false);
                    }}
                  />

                  <div className="submit-btn" style={{ paddingTop: "10px" }}>
                    <input type="submit" value="Continue" />
                  </div>

                  <div className="not-user">
                    Already a User? <Link to="/login">Sign In</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
