import "../assets/css/style2.css";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import SideBar from "../components/SideBar";
import patient_image2 from "../assets/img/default.jpg";
import box from "../assets/img/inbox-expand.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import DashSideNav from "../components/DashSideNav";
import { listPatient } from "../actions/patientActions";
import companyLogo from "../assets/img/logosync.png";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";

import "../assets/css/pateint.css";
// install Swiper modules
SwiperCore.use([Pagination, Scrollbar]);

export default function PatientListUpdated() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const patientList = useSelector((state) => state.patientList);
  const { error, loading, patients } = patientList;
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    dispatch(listPatient(token));
  }, [dispatch]);

  return (
    <>
      <section className="patient-list bg-light-pa">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col h-100">
              <div className=" d-flex justify-content-between pt-3 px-large-5 div-space">
                <div className="d-flex">
                  <h3 className="pateint-heading space-white">Patient Over</h3>
                  <form className="search-box d-flex">
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </form>
                </div>
                <div className="right-side">
                  <span className=" bg-light-grey-rad pateint-heading">
                    <button className="btn btn-transparent bt-hov active">
                      <a className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <rect
                            x="1"
                            y="1"
                            width="8.18734"
                            height="8.18734"
                            rx="3"
                            stroke="#092540"
                            strokeWidth="2"
                          />
                          <rect
                            x="15.1057"
                            y="1"
                            width="8.18734"
                            height="8.18734"
                            rx="3"
                            stroke="#092540"
                            strokeWidth="2"
                          />
                          <rect
                            x="15.1057"
                            y="14.3219"
                            width="8.18734"
                            height="8.18734"
                            rx="3"
                            stroke="#092540"
                            strokeWidth="2"
                          />
                          <rect
                            x="1"
                            y="14.3219"
                            width="8.18734"
                            height="8.18734"
                            rx="3"
                            stroke="#092540"
                            strokeWidth="2"
                          />
                        </svg>
                      </a>
                    </button>
                    <button className="btn btn-transparent bt-hov">
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            x="1.14746"
                            y="1.24335"
                            width="8.18734"
                            height="8.18734"
                            rx="3"
                            stroke="#092540"
                            strokeWidth="2"
                          />
                          <rect
                            x="1.14746"
                            y="14.5652"
                            width="8.18734"
                            height="8.18734"
                            rx="3"
                            stroke="#092540"
                            strokeWidth="2"
                          />
                          <path
                            d="M15.7014 3.10999H20.1454"
                            stroke="#092540"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M15.7014 16.442H20.1454"
                            stroke="#092540"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M15.7014 7.55402H22.3674"
                            stroke="#092540"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M15.7014 20.886H22.3674"
                            stroke="#092540"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </a>
                    </button>
                  </span>
                  <span>
                    <a>
                      Filter {""}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={25}
                        height={25}
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M4.5 20C4.5 20.2761 4.72386 20.5 5 20.5C5.27614 20.5 5.5 20.2761 5.5 20H4.5ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645L1.46447 7.82843C1.2692 8.02369 1.2692 8.34027 1.46447 8.53553C1.65973 8.73079 1.97631 8.73079 2.17157 8.53553L5 5.70711L7.82843 8.53553C8.02369 8.73079 8.34027 8.73079 8.53553 8.53553C8.7308 8.34027 8.7308 8.02369 8.53553 7.82843L5.35355 4.64645ZM5.5 20L5.5 5H4.5L4.5 20H5.5Z"
                          fill="black"
                        />
                        <path
                          d="M11 20H14"
                          stroke="black"
                          strokewidth="1.5"
                          strokelinecap="round"
                        />
                        <path
                          d="M11 15L17 15"
                          stroke="black"
                          strokewidth="1.5"
                          strokelinecap="round"
                        />
                        <path
                          d="M11 10L20 10"
                          stroke="black"
                          strokewidth="1.5"
                          strokelinecap="round"
                        />
                        <path
                          d="M11 5L23 5"
                          stroke="black"
                          strokewidth="1.5"
                          strokelinecap="round"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
              </div>
              <div className="px-large-5 h-100">
                <div className="div-tab table-responsive">
                  <table className="table table-hover border-light-g">
                    <thead>
                      <tr className="border-t">
                        <th scope="col">
                          <div className="form-check d-flex align-self-end">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="check1"
                              name="option1"
                              value="something"
                              checked
                            />
                            <label
                              className="form-check-label label-mar"
                              forHtml="check1"
                            >
                              Name{" "}
                              <svg
                                width="6"
                                height="18"
                                viewBox="0 0 6 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.4 7.23L3 4.63H2.94L1.54 7.23L0.42 6.66L2.42 3.02H3.62L5.59 6.66L4.4 7.23Z"
                                  fill="black"
                                />
                                <path
                                  d="M1.6 10.77L3 13.37L3.06 13.37L4.46 10.77L5.58 11.34L3.58 14.98L2.38 14.98L0.409999 11.34L1.6 10.77Z"
                                  fill="black"
                                />
                              </svg>
                            </label>
                          </div>
                        </th>

                        <th scope="col" className="th-hov">
                          <span className="d-flex">
                            <span>age </span>
                            <span>
                              <svg
                                width="6"
                                height="18"
                                viewBox="0 0 6 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.4 7.23L3 4.63H2.94L1.54 7.23L0.42 6.66L2.42 3.02H3.62L5.59 6.66L4.4 7.23Z"
                                  fill="black"
                                />
                                <path
                                  d="M1.6 10.77L3 13.37L3.06 13.37L4.46 10.77L5.58 11.34L3.58 14.98L2.38 14.98L0.409999 11.34L1.6 10.77Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </th>
                        <th scope="col" className="th-hov">
                          <span className="d-flex">
                            <span>gender </span>
                            <span>
                              <svg
                                width="6"
                                height="18"
                                viewBox="0 0 6 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.4 7.23L3 4.63H2.94L1.54 7.23L0.42 6.66L2.42 3.02H3.62L5.59 6.66L4.4 7.23Z"
                                  fill="black"
                                />
                                <path
                                  d="M1.6 10.77L3 13.37L3.06 13.37L4.46 10.77L5.58 11.34L3.58 14.98L2.38 14.98L0.409999 11.34L1.6 10.77Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </th>
                        <th scope="col" className="th-hov">
                          <span className="d-flex">
                            <span>Pateint#</span>{" "}
                            <span>
                              <svg
                                width="6"
                                height="18"
                                viewBox="0 0 6 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.4 7.23L3 4.63H2.94L1.54 7.23L0.42 6.66L2.42 3.02H3.62L5.59 6.66L4.4 7.23Z"
                                  fill="black"
                                />
                                <path
                                  d="M1.6 10.77L3 13.37L3.06 13.37L4.46 10.77L5.58 11.34L3.58 14.98L2.38 14.98L0.409999 11.34L1.6 10.77Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </th>
                        <th scope="col" className="th-hov">
                          <span className="d-flex">
                            <span>Last Visit</span>{" "}
                            <span>
                              <svg
                                width="6"
                                height="18"
                                viewBox="0 0 6 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.4 7.23L3 4.63H2.94L1.54 7.23L0.42 6.66L2.42 3.02H3.62L5.59 6.66L4.4 7.23Z"
                                  fill="black"
                                />
                                <path
                                  d="M1.6 10.77L3 13.37L3.06 13.37L4.46 10.77L5.58 11.34L3.58 14.98L2.38 14.98L0.409999 11.34L1.6 10.77Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                          </span>
                        </th>
                        <th scope="col" className="th-hov">
                          Condition{" "}
                          <svg
                            width="6"
                            height="18"
                            viewBox="0 0 6 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.4 7.23L3 4.63H2.94L1.54 7.23L0.42 6.66L2.42 3.02H3.62L5.59 6.66L4.4 7.23Z"
                              fill="black"
                            />
                            <path
                              d="M1.6 10.77L3 13.37L3.06 13.37L4.46 10.77L5.58 11.34L3.58 14.98L2.38 14.98L0.409999 11.34L1.6 10.77Z"
                              fill="black"
                            />
                          </svg>
                        </th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {patients?.map((b) => (
                        <tr key={b._id}>
                          <th scope="row">
                            {" "}
                            <div className="form-check d-flex align-self-end">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="check1"
                                name="option1"
                                value="something"
                                checked
                              />
                              <label
                                className="form-check-label label-mar"
                                forHtml="check1"
                              >
                                {b.name}
                              </label>
                            </div>
                          </th>
                          <td>{b.dob}</td>
                          <td>{b.gender}</td>
                          <td>01-23-12</td>
                          <td>{b.first_visit_date}</td>
                          <td>{b.clinical_condition}</td>
                          <td className="d-up">
                            <div className="space-up">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
                                    stroke="#092540"
                                    strokeWidth="2"
                                  />
                                  <path
                                    d="M18 16H16M16 16H14M16 16V14M16 16V18"
                                    stroke="#092540"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M7 4V2.5"
                                    stroke="#092540"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M17 4V2.5"
                                    stroke="#092540"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M2.5 9H21.5"
                                    stroke="#092540"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </span>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 8V12L14.5 14.5"
                                    stroke="#092540"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4.33333 7.74551L4.45833 7.74614L6.75388 7.75767L6.87888 7.7583C6.87894 7.7583 6.879 7.75831 6.87906 7.75831L7.00388 7.75893L7.00392 7.75007C7.42826 7.69156 7.75605 7.32852 7.75827 6.88771C7.76048 6.44691 7.43636 6.0806 7.01263 6.01782L7.01268 6.00896L6.88768 6.00833L6.76268 6.0077L6.44807 6.00612C9.61297 3.07593 14.5918 3.16544 17.7132 6.28682C20.9097 9.4833 20.9266 14.6276 17.7771 17.7771C14.6276 20.9266 9.48327 20.9097 6.28679 17.7132C6.28601 17.7124 6.28522 17.7117 6.28444 17.7109C4.3942 15.8191 3.61574 13.2439 3.9504 10.802C3.95041 10.802 3.95041 10.8019 3.95042 10.8019L3.9674 10.678L3.95862 10.6768C3.95611 10.2485 3.63907 9.87604 3.20234 9.81618C2.76563 9.75632 2.36006 10.0297 2.24238 10.4416L2.2336 10.4404L2.21663 10.5642C2.21663 10.5642 2.21663 10.5642 2.21663 10.5642C2.21659 10.5645 2.21656 10.5647 2.21653 10.5649L2.19965 10.6881L2.20044 10.6882C1.84144 13.579 2.76417 16.6099 4.9615 18.8617L4.96096 18.8622L5.04921 18.9505C5.04926 18.9505 5.0493 18.9505 5.04935 18.9506C5.05025 18.9515 5.05115 18.9524 5.05205 18.9533L5.13774 19.039L5.13828 19.0384C8.98277 22.7899 15.1038 22.8295 18.9256 19.1024L18.9262 19.103L19.0146 19.0146L19.1029 18.9262L19.1024 18.9257C22.8294 15.1038 22.7899 8.98281 19.0384 5.13831L19.0389 5.13777L18.9506 5.04939L18.8622 4.961L18.8616 4.96154C15.0597 1.25166 9.03157 1.17189 5.20221 4.7751L5.20056 4.44558L5.19993 4.32058L5.1993 4.19558L5.19044 4.19562C5.12766 3.7719 4.76134 3.44777 4.32054 3.44999C3.87973 3.4522 3.51671 3.77999 3.45818 4.20433L3.44932 4.20438L3.44995 4.32937L3.45058 4.45437L3.46211 6.74993L3.46274 6.87492C3.46274 6.87495 3.46274 6.87498 3.46274 6.87501L3.46337 6.99992L3.47223 6.99988C3.52852 7.37961 3.82864 7.67974 4.20838 7.73602L4.20833 7.74489L4.33333 7.74551Z"
                                    fill="#092540"
                                    stroke="#092540"
                                    strokeWidth="0.25"
                                  />
                                </svg>
                              </span>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6"
                                    stroke="#092540"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M19.7188 18.3906L13.325 12.0004L19.7188 5.65714C20.0392 5.28603 20.0219 4.72911 19.679 4.37894C19.3361 4.02878 18.7832 4.00341 18.4101 4.32073L11.9976 10.6169L5.69734 4.27367C5.33275 3.90878 4.74392 3.90878 4.37933 4.27367C4.20236 4.45039 4.10282 4.69094 4.10282 4.94188C4.10282 5.19282 4.20236 5.43337 4.37933 5.61008L10.6703 11.9439L4.2765 18.2777C4.09954 18.4544 4 18.695 4 18.9459C4 19.1969 4.09954 19.4374 4.2765 19.6141C4.45291 19.7903 4.69172 19.8885 4.94018 19.887C5.18409 19.8885 5.41891 19.794 5.59452 19.6235L11.9976 13.2709L18.4101 19.7271C18.5865 19.9032 18.8253 20.0014 19.0738 20C19.319 19.9989 19.554 19.9009 19.7281 19.7271C19.9039 19.5491 20.0017 19.3078 20 19.0569C19.9982 18.8059 19.897 18.5661 19.7188 18.3906Z"
                                    fill="#092540"
                                  />
                                </svg>
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn btn-transparent dropdown-toggle  drop-b"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-v  drop-b"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <ul
                                className="dropdown-menu ul-man"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li>
                                  <a
                                    className="dropdown-item item-dro d-flex justify-content-between"
                                    href="#"
                                  >
                                    <span>Followup</span>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
                                          stroke="#092540"
                                          strokeWidth="2"
                                        />
                                        <path
                                          d="M18 16H16M16 16H14M16 16V14M16 16V18"
                                          stroke="#092540"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M7 4V2.5"
                                          stroke="#092540"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M17 4V2.5"
                                          stroke="#092540"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                        />
                                        <path
                                          d="M2.5 9H21.5"
                                          stroke="#092540"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item item-dro  d-flex justify-content-between"
                                    href="#"
                                  >
                                    <span>Veiw History</span>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M12 8V12L14.5 14.5"
                                          stroke="#092540"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4.33333 7.74551L4.45833 7.74614L6.75388 7.75767L6.87888 7.7583C6.87894 7.7583 6.879 7.75831 6.87906 7.75831L7.00388 7.75893L7.00392 7.75007C7.42826 7.69156 7.75605 7.32852 7.75827 6.88771C7.76048 6.44691 7.43636 6.0806 7.01263 6.01782L7.01268 6.00896L6.88768 6.00833L6.76268 6.0077L6.44807 6.00612C9.61297 3.07593 14.5918 3.16544 17.7132 6.28682C20.9097 9.4833 20.9266 14.6276 17.7771 17.7771C14.6276 20.9266 9.48327 20.9097 6.28679 17.7132C6.28601 17.7124 6.28522 17.7117 6.28444 17.7109C4.3942 15.8191 3.61574 13.2439 3.9504 10.802C3.95041 10.802 3.95041 10.8019 3.95042 10.8019L3.9674 10.678L3.95862 10.6768C3.95611 10.2485 3.63907 9.87604 3.20234 9.81618C2.76563 9.75632 2.36006 10.0297 2.24238 10.4416L2.2336 10.4404L2.21663 10.5642C2.21663 10.5642 2.21663 10.5642 2.21663 10.5642C2.21659 10.5645 2.21656 10.5647 2.21653 10.5649L2.19965 10.6881L2.20044 10.6882C1.84144 13.579 2.76417 16.6099 4.9615 18.8617L4.96096 18.8622L5.04921 18.9505C5.04926 18.9505 5.0493 18.9505 5.04935 18.9506C5.05025 18.9515 5.05115 18.9524 5.05205 18.9533L5.13774 19.039L5.13828 19.0384C8.98277 22.7899 15.1038 22.8295 18.9256 19.1024L18.9262 19.103L19.0146 19.0146L19.1029 18.9262L19.1024 18.9257C22.8294 15.1038 22.7899 8.98281 19.0384 5.13831L19.0389 5.13777L18.9506 5.04939L18.8622 4.961L18.8616 4.96154C15.0597 1.25166 9.03157 1.17189 5.20221 4.7751L5.20056 4.44558L5.19993 4.32058L5.1993 4.19558L5.19044 4.19562C5.12766 3.7719 4.76134 3.44777 4.32054 3.44999C3.87973 3.4522 3.51671 3.77999 3.45818 4.20433L3.44932 4.20438L3.44995 4.32937L3.45058 4.45437L3.46211 6.74993L3.46274 6.87492C3.46274 6.87495 3.46274 6.87498 3.46274 6.87501L3.46337 6.99992L3.47223 6.99988C3.52852 7.37961 3.82864 7.67974 4.20838 7.73602L4.20833 7.74489L4.33333 7.74551Z"
                                          fill="#092540"
                                          stroke="#092540"
                                          strokeWidth="0.25"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item item-dro d-flex justify-content-between"
                                    href="#"
                                  >
                                    <span>Edit Pateint</span>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6"
                                          stroke="#092540"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item item-dro  d-flex justify-content-between"
                                    href="#"
                                  >
                                    <span>Delete Pateint</span>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M19.7188 18.3906L13.325 12.0004L19.7188 5.65714C20.0392 5.28603 20.0219 4.72911 19.679 4.37894C19.3361 4.02878 18.7832 4.00341 18.4101 4.32073L11.9976 10.6169L5.69734 4.27367C5.33275 3.90878 4.74392 3.90878 4.37933 4.27367C4.20236 4.45039 4.10282 4.69094 4.10282 4.94188C4.10282 5.19282 4.20236 5.43337 4.37933 5.61008L10.6703 11.9439L4.2765 18.2777C4.09954 18.4544 4 18.695 4 18.9459C4 19.1969 4.09954 19.4374 4.2765 19.6141C4.45291 19.7903 4.69172 19.8885 4.94018 19.887C5.18409 19.8885 5.41891 19.794 5.59452 19.6235L11.9976 13.2709L18.4101 19.7271C18.5865 19.9032 18.8253 20.0014 19.0738 20C19.319 19.9989 19.554 19.9009 19.7281 19.7271C19.9039 19.5491 20.0017 19.3078 20 19.0569C19.9982 18.8059 19.897 18.5661 19.7188 18.3906Z"
                                          fill="#092540"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
