import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AdminPanelHeader from "./AdminPanelHeader";

export default function PatientVisits(props) {
  const [patientVisits, setPatientVisits] = useState([]);
  const [patientName, setPatientName] = useState();
  const token = useSelector((state) => state.loginData.token);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/patient_visit_detail/${props.match.params.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.code === 401) {
        // localStorage.clear();
        // window.location.reload();
      } else {
        setPatientVisits(response.data.data.visits);
        setPatientName(response.data.data.patient_name);
        if (response.data.data.visits.length > 0) {
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
      }
    };
    getData();
  }, [props.match.params.id, token]);

  return (
    <div>
      <AdminPanelHeader />
      <div className="overview-title" style={{ textAlign: "center" }}>
        <h3>Patient Visits - {patientName}</h3>
      </div>

      {isEmpty ? (
        <div
          className="overview-title"
          style={{ textAlign: "center", paddingTop: "10px" }}
        >
          <h3 style={{ color: "red" }}>No recordings available </h3>
        </div>
      ) : (
        <div>
          <table className="table">
            <thead>
              <tr
                style={{
                  color: "#166e78",
                  fontSize: "22px",
                  textAlign: "center",
                }}
              >
                {/* <th scope="col">Patient ID</th> */}
                <th scope="col">#</th>
                <th scope="col">Timestamp</th>
                <th scope="col">Duration</th>
                <th scope="col">Location</th>
                <th scope={"col"}>Condition</th>
                <th scope="col">Recordings</th>
              </tr>
            </thead>
            <tbody>
              {patientVisits.map((item, index) => (
                <tr style={{ textAlign: "center" }} key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.timestamp}</td>
                  <td>{item.duration == null ? "N/A" : item.duration} </td>
                  <td>{item.location}</td>
                  <td>
                    {item.clinical_condition == "1"
                      ? "Seizure"
                      : item.clinical_condition == "2"
                      ? "Concussions"
                      : item.clinical_condition == "3"
                      ? "Stroke"
                      : ""}
                  </td>
                  <td>
                    {" "}
                    <Link
                      to={`/recording/${item.id}`}
                      className="btn btn-info btn-block "
                      onClick={() => {
                        localStorage.setItem("patientVisitId", item.id);
                      }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
