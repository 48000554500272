import "../assets/css/style2.css";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, } from "react-bootstrap";
import SideBar from "../components/SideBar";
import patient_image2 from "../assets/img/default.jpg";
import box from "../assets/img/inbox-expand.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import DashSideNav from "../components/DashSideNav";
import { listPatient } from "../actions/patientActions";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";
// install Swiper modules
SwiperCore.use([Pagination, Scrollbar]);

export default function ViewPatients() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const patientList = useSelector((state) => state.patientList);
  const { error, loading, patients } = patientList;
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    dispatch(listPatient(token));
  }, [dispatch ]);

  return (
    <>
      <section className="dashbpard-sections">
        <div className="container-fluid">
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-6">
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-9 ">
              <div>
                {/* <!-- Account details card--> */}
                <div className="card mb-4">
                  <div className="card-header text-cyan">Patient List</div>
                  <div className="card-body">
                  {patients.length > 0 ? (
                    <table striped bordered hover responsive className="table small mb-1 text-gray-700">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>NAME</th>
                          <th>DOB</th>
                          <th>Gender</th>
                          <th>First Visit Date</th>
                          <th>Condition</th>
                        </tr>
                      </thead>  
                      <tbody>
                        {patients?.map((b) => (
                          <tr key={b._id}>
                            <td>{b.id}</td>
                            <td>{b.name}</td>
                            <td>{b.dob}</td>
                            <td>{b.gender}</td>
                            <td>{b.first_visit_date}</td>
                            <td>{b.clinical_condition}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
          ) : (

            <div
              className="overview-title"
              style={{ textAlign: "center", paddingTop: "10px" }}
            >
              <h4 style={{ color: "red" }}>No patient records available </h4>
              <br />
              <a href="/patients"className="btn btn-info btn-block   w-50" >
                          Add new patient
                        </a>
            </div>         
          )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Swiper JS */}
    </>
  );
}
