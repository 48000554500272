import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadStripe } from "@stripe/stripe-js";

import QueryString from "query-string";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import "../assets/css/style2.css";
import companyLogo from "../assets/img/Company_logo_500x250.png";
export default function PaymentPlan() {
  const location = useLocation();
  const stripe = loadStripe(
    "pk_test_51L8OQPDSGtKetiSULvv0qIvrud9sorFxPlZcyTUw6cgfQwWTUtwovXaMCktYfRlzEUb8EMIbJGEYqR32Gt8HkFWe00zaND3eu0"
  );

  const API_URL = process.env.REACT_APP_BASE_URL;
  const api = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-type": "application/json",
    },
  });

  var myHeaders = new Headers();
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(`${API_URL}/config/`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    // const query = new URLSearchParams(window.location.search);
    const values = QueryString.parse(location.search);

    if (values.success) {
      console.log("Order placed! You will receive an email confirmation.");
    }

    if (values.canceled) {
      console.log(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return (
    <>
      <div className="login-bg">
        <div className="boxes-in-bg" />
      </div>
      {/* Login Section */}
      <section className="login-page">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4">
              <div className="login-logo text-center">
                <Link to="/">
                  <img
                    className="logo-img"
                    src={companyLogo}
                    alt="company-logo"
                  />
                </Link>
              </div>

              <CardGroup className="">
                <Card className="m-5 text-center border rounded">
                  <Card.Body className=" p-0">
                    <Card.Title className=" p-3  text-center font-weight-bold text-black">
                      Monthly Plan 100$
                    </Card.Title>
                    <Card.Text>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <i className="crossicon">
                            <FontAwesomeIcon icon={faXmark} />
                          </i>
                          Morbi leo risus
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {" "}
                          <i className="iconcolor">
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          Cras justo odio
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Text>
                  </Card.Body>
                  <form
                    action={`${API_URL}/api/stripe/create-checkout-session`}
                    method="POST" className="bgcolour"
                  >
                    <Button
                      className="textcolor border-0 font-weight-bold btnhover bgcolour"
                      variant="success"
                      id="submitBtn"
                      type="submit"
                    >
                      Start Subscription
                    </Button>
                  </form>
                </Card>
              </CardGroup>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
