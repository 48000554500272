import React from "react";
import "../assets/css/style2.css";
import axios from "axios";
import { logoutUser } from "../redux/logins";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const SideBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  function logout() {
    axios.get(`${process.env.REACT_APP_BASE_URL}/logout`).then((res) => {
      if (res.data.status === true) {
        dispatch(logoutUser());
        localStorage.clear();
        history.push({
          pathname: "/login",
        });
      }
    });
  }

  return (
    <div className="left-menu-desktop">
      <ul className="left-menu">
        <li>
          <a href="/#">Notifications</a>
        </li>
        <li>
          <a href="/patients">New Patient</a>
        </li>
        <li>
          <a href="/#">All Records</a>
        </li>
        <li>
          <a href="/#">Settings</a>
        </li>
        <li>
          <a href="/#">Support</a>
        </li>
      </ul>
      <button className="logout btn btn-lg btn-danger" onClick={logout}>
        Sign Out
      </button>
    </div>
  );
};

export default SideBar;
