import React, { useState } from "react";
import companyLogo from "../assets/img/logosync.png";
import "../assets/css/login.css";

const Header = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg custom-style navbar-light px-large-5">
        <div className="container-fluid padd-0">
          <a className="navbar-brand" href="/dashboard">
            <img src={companyLogo} className="logo-image-className" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ps-5">
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-style dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Clinic
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="dropdown-item item-dro d-flex justify-content-between"
                      href="#"
                    >
                      <span>Patients</span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M14 8H19M15 12H19M17 16H19M17 21H7C3 21 2 20 2 16V8C2 4 3 3 7 3H17C21 3 22 4 22 8V16C22 20 21 21 17 21Z"
                            stroke="#092540"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 16.33C11.9327 15.628 11.6219 14.9718 11.1214 14.475C10.621 13.9782 9.96243 13.6721 9.26 13.61C8.75457 13.56 8.24543 13.56 7.74 13.61C7.03826 13.674 6.38078 13.9806 5.88069 14.4771C5.3806 14.9735 5.06918 15.6287 5 16.33M8.5 11.29C8.98004 11.29 9.44042 11.0993 9.77986 10.7598C10.1193 10.4204 10.31 9.96002 10.31 9.47998C10.31 8.99994 10.1193 8.53956 9.77986 8.20012C9.44042 7.86068 8.98004 7.66998 8.5 7.66998C8.01996 7.66998 7.55958 7.86068 7.22014 8.20012C6.8807 8.53956 6.69 8.99994 6.69 9.47998C6.69 9.96002 6.8807 10.4204 7.22014 10.7598C7.55958 11.0993 8.01996 11.29 8.5 11.29Z"
                            stroke="#092540"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item item-dro  d-flex justify-content-between"
                      href="#"
                    >
                      <span>Recordings</span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="18"
                          viewBox="0 0 22 18"
                          fill="none"
                        >
                          <path
                            d="M1 9.76923H4.90618C5.31514 9.76923 5.68289 9.5202 5.83472 9.14046L8.60622 2.20827C8.97825 1.27773 10.3356 1.41201 10.518 2.39741L12.9091 15.3093C13.0957 16.3168 14.4965 16.4252 14.836 15.4585L16.5986 10.438C16.7392 10.0374 17.1175 9.76923 17.5421 9.76923H21"
                            stroke="#092540"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-style" href="#">
                  Research
                </a>
              </li>
              {/* <!-- <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Clinic
                      </a>
                    </li> -->
             */}
            </ul>
            <ul className="user-icon">
              <li>{/* <!-- <img src="#"></img> --> */}</li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
