import "./App.css";
import Home from "./components/Home";
import Login from "./components/Login";
import UpdatedLogin from "./components/UpdatedLogin";

import AdminPanelHeader from "./components/AdminPanelHeader";
import Header from "./components/Header";
import Dashboard from "./components/Dashboard";
import NewPatient from "./screens/NewPatient";
import Recording2 from "./components/Recording2";
import PatientVisits from "./components/PatientVisits";
import SignUp from "./components/SignUp";
import HomePage from "./screens/HomePage";
import PenDetail from "./screens/PenDetail";
import Test from "./screens/Test";
import ViewPatients from "./screens/ViewPatients";
// import PatientListUpdated from "./components/PatientListUpdated"
import RecordingAll from "./components/RecordingAll";
import Epochs from "./components/epochs";
import EpochsVisuals from "./components/EpochsVisuals";
import PaymentPlan from "./components/PaymentPlan";
import PaymentCard from "./components/Card";


import FileUpload from "./components/FileUpload";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import PatientListUpdated from "./components/PatientListUpdated";

function App() {
  const stateValue = useSelector((state) => state.loginData.status);

  const ProtectedRoutes = () => {
    if (stateValue === false) {
      return (
        <Switch>
          {/* <Route path="/home" exact>
            <Home></Home>
          </Route> */}

          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/technology" exact>
            <PenDetail />
          </Route>

          <Route path="/test" exact>
            <Test />
          </Route>

          <Route path="/login" exact>
            
            <UpdatedLogin></UpdatedLogin>
          </Route>

          <Route path="/register" exact>
            <SignUp></SignUp>
          </Route>
          <Route path="/payment" exact>
            <PaymentPlan />
          </Route>
          <Route path="/card" exact>
            <PaymentCard />
          </Route>



          <Redirect from="*" to="/" />

        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/technology" exact>
            <PenDetail />
          </Route>

          <Route path="/test" exact>
            <Test />
          </Route>

          <Route path="/all-recordings" exact>
            <RecordingAll />
          </Route>

          <Route path="/epochs" exact>
            <Epochs />
          </Route>


          <Route path="/epochs-display/:key" exact component={EpochsVisuals} />

          <Route path="/dashboard" exact>
            <AdminPanelHeader></AdminPanelHeader>
            <Dashboard></Dashboard>
          </Route>


          <Route path="/payment" exact>
            <PaymentPlan />
          </Route>

          <Route path="/patients" exact>
            <AdminPanelHeader></AdminPanelHeader>
            <NewPatient />
          </Route>

          <Route path="/listpatients" exact>
            {/* <AdminPanelHeader></AdminPanelHeader> */}
            {/* <PatientListUpdated /> */}
            {/* <ViewPatients /> */}
            <Header></Header>
            <PatientListUpdated></PatientListUpdated>
          </Route>

          <Route path="/recording/:id" exact component={Recording2} />

          <Route path="/visits/:id" exact component={PatientVisits} />
          <Route path="/fileupload/:id" exact component={FileUpload} />

          <Redirect from="*" to="/dashboard" />
        </Switch>
      );
    }
  };

  return (
    <div className="App">
      <Router>
        {/* <Switch>
          <Route path="/" exact>
            <Home></Home>
          </Route>

          <Route path="/login" exact>
            <Login></Login>
          </Route>

          <Route path="/dashboard" exact>
            <AdminPanelHeader></AdminPanelHeader>
            <Dashboard></Dashboard>
          </Route>

          <Route path="/recording/:id" exact component={Recording2} />

          <Route path="/visits/:id" exact component={PatientVisits} />
        </Switch> */}
        <ProtectedRoutes />
      </Router>
    </div>
  );
}

export default App;
